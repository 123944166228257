import { React } from "react";

import logo from "../Images/logo-new.png";

import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Privacy Policy";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="redem-home">
        <div className="voucher-section">
          <div className="voucher-entry">
            <img src={logo} alt="logo..." className="logo-redem" />
            <div className="read-carefully thank">
              Stegers Furniture Sweepstakes PRIVACY STATEMENT
            </div>
            <h4 className="thank-head">PRIVACY STATEMENT</h4>
          </div>
        </div>
        <div className="infor-section thankyou">
          <ul>
            <li className="active">
              <div className="info-text">
                <h6>Site Security Features</h6>
                Stegers Furniture Sweepstakes realizes how important security is
                to our clients and to their participants, so we’ve taken a
                number of steps to enhance the protection of personal or
                confidential information sent to or from Stegers Furniture
                Sweepstakes or in accessing information resident at Stegers
                Furniture Sweepstakes . This includes username, password, and
                any debit card information. First, we require unique usernames
                and password establishment that are not easily determined by
                someone other than the intended participant. This requirement
                protects personal information and access to personal earnings
                that are available for redemption.
              </div>
            </li>

            <li>
              <div className="info-text">
                <h6>SSL Technology</h6>
                Stegers Furniture Sweepstakes requires that a “secure session”
                is established, using Secure Socket Layer (SSL) technology. This
                is done anytime a participant supplies Stegers Furniture
                Sweepstakes with personal or confidential information in one of
                the secure areas of a website.
                <br /> SSL technology creates a private conversation that only
                the participant’s computer and Stegers Furniture Sweepstakes can
                understand. The SSL technology encodes information as it is
                being sent over the Internet between the participant’s computer
                and ER’s system helping to ensure that the transmitted
                information remains confidential.
                <br /> The use of SSL requires two components: and
                SSL-compatible browser and a Web server to perform
                “key-exchange” that establishes a secure connection to Stegers
                Furniture Sweepstakes web servers.
              </div>
            </li>
            <li>
              <div className="info-text">
                <h6>Browser Compliance</h6>
                Participants and client will need a browser with SSL
                capabilities. Examples of SSL browsers include, Microsoft’s
                Internet Explorer, Firefox, Chrome and Safari. If a participant
                does not have a browser with SSL capabilities, the participant
                can download an SSL browser from the above mentioned SSL browser
                list and will be required in order to securely access personal
                or confidential information via the Internet. Stegers Furniture
                Sweepstakes codes sites to current browser version minus 1.
                Stegers Furniture Sweepstakes recommends the use of the latest
                browser versions available. Accessing secure online areas
                requires SSL capable browsers due to security concerns.
              </div>
            </li>
            <li>
              <div className="info-text">
                <h6>ISP Compliance</h6>
                Nearly all Internet Service Providers (ISPs) automatically
                enable the SSL session described above. If a participant or a
                client contact uses their company's internal connection to
                access the Internet they may find they cannot access the Stegers
                Furniture Sweepstakes secure pages with an SSL browser described
                above, the company may be blocking access via a "firewall." The
                company’s Internet access systems administrator would have to be
                contacted for further details on Internet access.
              </div>
            </li>
            <li>
              <div className="info-text">
                <h6>Cookies</h6>A participant or client must have enabled
                cookies on their browser in order to access confidential
                information. If they have chosen to disable cookies on their
                browser, they will not be able to access confidential
                information.
              </div>
            </li>
            <li className="pb-0">
              <div className="info-text">
                <h6>Third Party Use</h6>
                Stegers Furniture Sweepstakes does not sell, trade or rent
                personal information to third parties. We do, however, share
                your shipping information with our suppliers for the sole
                purpose of delivering your redemption items.
              </div>
            </li>

            <div className="new-footer">
              <p>
                © Copyright {new Date().getFullYear()}. All rights reserved.{" "}
                {process.env.REACT_APP_API_SITE_TITLE}.
              </p>
            </div>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </>
  );
}
export default PrivacyPolicy;
